/* --------------------------------
   start screen
-------------------------------- */

.start-screen
{
	@extend %pos-relative;
	color: $white;
	height: 100vh;
	min-height: 560px;

	&__static-bg,
	&__slider
	{
		position: absolute !important;
		@extend %block-absolute--full;
	}

	&--static-bg
	{
		&--style-1
		{
			.start-screen__static-bg
			{
				&:after
				{
					content: "";
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 115px;
					background-image: url(../img/home_img/bevel.png);
					background-position: 0 0;
				}
			}
		}
	}

	&__static-bg
	{
		background-color: $secondary-color;
		@extend %bg-cover;
	}

	&--slider
	{
		.start-screen__content
		{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			visibility: hidden;
			opacity: 0;
			@include transition(
				opacity 300ms ease-in-out,
				visibility 300ms ease-in-out
			);

			&.active
			{
				position: relative;
				top: auto;
				left: auto;
				right: auto;
				visibility: visible;
				opacity: 1;

				@include transition(
					opacity 500ms ease-in-out 200ms,
					visibility 300ms ease-in-out
				);
			}
		}
	}

	&__slider
	{
		height: 100% !important;

		.vegas-dots
		{
			@extend %pos-absolute;
			left: 0;
			bottom: 40px;
			@extend %width-100;
			line-height: 0;
			@extend %text-center;
			z-index: 3;

			a
			{
				@extend %display-inline-block;
				@extend %v-top;
				width: 15px;
				height: 15px;
				margin: 0 5px;
				cursor: pointer;
				background-color: $white;
				@include border-radius(50%);
				@include transition(background-color 0.3s ease-in-out);

				&:hover { background-color: lighten($primary-color, 20%); }

				&.active
				{
					cursor: default;
					background-color: $primary-color;
				}
			}
		}
	}

	&__content-container
	{
		@extend %pos-relative;
		@extend %height-100;
		padding-top: 100px;
		padding-bottom: 80px;
	}

	&__content
	{
		@extend %height-100;

		p
		{
			margin: 20px 0;

			&:first-child { margin-top: 0; }
			&:last-child { margin-bottom: 0; }
		}
	}

	.title
	{
		line-height: 1;
		font-size: rem-calc(35px);
	}

	form
	{
		background-color: $secondary-color;
		@extend %text-left;
		padding: 30px 30px 0;
		margin-top: 40px;
		z-index: 3;

		.input-wrp
		{
			input,
			textarea
			{
				border-bottom-color: #616161;
				color: #d0d0d0;
				@include placeholder { color: #d0d0d0; }
			}

			i { color: #747474; }
		}

		.btn-wrp { margin: 0 -30px; }

		button[type=submit]
		{
			background-color: $primary-color;
			color: $white;
			height: 68px;
			border: none;
			border-radius: 0;

			&:hover,
			&:focus
			{
				background-color: darken(desaturate(adjust-hue($primary-color, -0.2098), 10.6725), 9.2157);
			}
		}

		.text
		{
			margin-bottom: 20px;
			font-size: rem-calc(14px);
			color: #9d9d9d;
		}

		.heading { color: $white; }

		.note
		{
			line-height: 1;
			font-size: rem-calc(12px);
			color: #818181;
			margin: 45px 0;
		}
	}

	&__btn
	{
		@extend %pos-relative;
		@extend %display-inline-block;
		@extend %v-middle;
		line-height: 1.1;
		font: {
			weight: 400;
			size: rem-calc(16px);
			family: $base-fontFamily-1;
		};
		color: $white !important;
		@extend %text-center;
		text-decoration: none;
		box-shadow: none;
		outline: none;
		background-color: #c73232;
		min-width: 180px;
		min-height: 58px;
		padding: 20px 10px;
		@include border-radius(5px);
		@include transition(
			background-color 0.3s ease-in-out,
			border-color 0.3s ease-in-out,
			color 0.3s ease-in-out
		);

		&:hover,
		&:focus
		{
			background-color: $secondary-color;
		}
	}
}

@include min-screen(481px)
{
	.start-screen
	{
		&__content-container
		{
			padding-top: 115px;
			padding-bottom: 115px;
		}

		.title { font-size: rem-calc(45px); }

		&__btn { margin-top: 20px; }
	}
}

@include min-screen($sm-width)
{
	.start-screen
	{
		&--static-bg--style-1
		{
			max-height: 750px;
			border-bottom: 50px solid transparent;

			.start-screen__content-container { padding-bottom: 0; }
		}

		.title { font-size: rem-calc(50px); }

		form { margin-top: 0; }
	}
}

@include min-screen($md-width)
{
	.start-screen
	{
		.title { font-size: rem-calc(70px); }
	}
}




@include max-screen($sm-width - 1)
{
	.start-screen
	{
		&--static-bg--style-1 { height: auto; }
	}
}

@include screen($sm-width,$md-width)
{
}