/* --------------------------------
   about
-------------------------------- */

.section-about
{
	&--style-1
	{
		a:not(.custom-btn) { color: inherit; }

		.img-place
		{
			img
			{
				max-width: 100%;
				height: auto;
			}
		}
	}
}

@include min-screen($sm-width) { }

@include min-screen($md-width)
{
	.section-about
	{
		&--style-1
		{
			margin-bottom: 100px;

			&:before
			{
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				width: 100%;
				max-width: 230px;
				background-color: $white;
				z-index: 0;
			}

			.img-place
			{
				position: absolute;
				top: 0;
				right: 0;

				img { max-width: none; }
			}
		}
	}
}

@include min-screen($lg-width)
{
	.section-about
	{
		&--style-1
		{
			&:before
			{
				max-width: 330px;
			}
		}
	}
}