/* --------------------------------
   top bar
-------------------------------- */

#top-bar
{
	@extend %pos-absolute;
	top: 0;
	left: 0;
	@extend %width-100;
	z-index: 6;

	&__logo
	{
		@extend %display-block;
		@extend %fl-l;
		width: 141px;
		height: 27px;
		overflow: hidden;
		text-indent: 100%;
		white-space: nowrap;
		background: url(../img/site_logo.png);
	}

	&__navigation-toggler
	{
		position: absolute;
		top: -7px;
		right: 15px;
		padding: 19px 10px;

		span
		{
			@extend %display-block;
			@extend %pos-relative;
			height: 3px;
			width: 20px;

			&:before,
			&:after
			{
				content: "";
				@extend %pos-absolute;
				left: 0;
				@extend %width-100;
				@extend %height-100;
			}

			&:before { top: -6px; }
			&:after { top: 6px; }
		}

		span,
		span:before,
		span:after
		{
			background-color: $white;

			@include transition(all 400ms ease-in-out);
		}

		&.active
		{
			span
			{
				background-color: transparent;

				&:before,
				&:after
				{
					top: 0;

					-webkit-transform-origin: 50% 50%;
					-moz-transform-origin: 50% 50%;
					-ms-transform-origin: 50% 50%;
					-o-transform-origin: 50% 50%;
					transform-origin: 50% 50%;
				}

				&:before
				{
					-webkit-transform: rotate(225deg);
					-ms-transform: rotate(225deg);
					-o-transform: rotate(225deg);
					transform: rotate(225deg);
				}

				&:after
				{
					-webkit-transform: rotate(-225deg);
					-ms-transform: rotate(-225deg);
					-o-transform: rotate(-225deg);
					transform: rotate(-225deg);
				}
			}
		}
	}

	&__navigation
	{
		@extend %fl-r;
		padding-top: 10px;

		.fixed & { padding-top: 5px; }

		ul
		{
			line-height: 0;
			font-size: 0;
			letter-spacing: -1px;
			white-space: nowrap;
		}

		li { @extend %pos-relative; }

		a
		{
			color: $white;

			&:not(.custom-btn)
			{
				@extend %display-block;
				line-height: 1;
				letter-spacing: 0;
				white-space: normal;
				outline: none;
				padding: 3px 5px;
			}
		}
	}
}

@include min-screen($md-width)
{
	#top-bar
	{
		padding-top: 55px;

		.desktop &
		{
			animation-duration: .3s;
			animation-fill-mode: both;
		}

		.desktop &.in
		{
			-webkit-animation-name: TopBarSlideInDown;
			animation-name: TopBarSlideInDown;
		}

		.desktop &.out
		{
			-webkit-animation-name: TopBarSlideOutUp;
			animation-name: TopBarSlideOutUp;
		}

		&.fixed
		{
			position: fixed;
			height: 80px;
			padding-top: 25px;
			padding-bottom: 25px;
			background-color: $secondary-color;
		}

		&__navigation-toggler { display: none; }

		&__navigation
		{
			ul {}

			li
			{
				display: inline-block;
				vertical-align: middle;
				margin-left: 10px;

				&:first-child { margin-left: 0; }

				&.menu-item--button
				{
					margin-top: -12px;
					margin-bottom: -10px;
					margin-left: 20px;
				}

				&:not(.menu-item--button)
				{
					&:before,
					&:after
					{
						content: "";
						position: absolute;
						left: 5px;
						width: 5px;
						background-color: $primary-color;
						opacity: 0;
						@include transition(opacity 0.3s ease-in-out);
					}

					&:before
					{
						bottom: 100%;
						margin-bottom: 7px;
						height: 80px;
					}

					&:after
					{
						top: 100%;
						margin-top: 7px;
						height: 12px;

						.fixed & { height: 20px; }
					}

					&.active,
					&:hover
					{
						&:before,
						&:after
						{
							opacity: 1;
						}
					}
				}
			}

			a { font-size: rem-calc(16px); }
		}
	}
}

@include max-screen($md-width - 1)
{
	#top-bar
	{
		position: fixed;
		height: 80px;
		padding-top: 25px;
		padding-bottom: 25px;
		background-color: $secondary-color;
		overflow: hidden;

		&.expanded
		{
			&,
			>.container
			{
				height: 100%;
			}
		}

		&__navigation
		{
			position: absolute;
			top: 75px;
			right: 15px;
			bottom: 0;
			left: 15px;
			padding: 0 !important;
			text-align: center;
			overflow-y: auto;

			&:before,
			ul
			{
				display: inline-block;
				vertical-align: middle;
			}

			&:before
			{
				content: "";
				width: 0;
				height: 100%;
				margin-left: -4px;
			}

			li
			{
				display: block;
				margin-top: 10px;

				&:first-child { margin-top: 0; }

				&.active a,
				&:hover a
				{
					color: $primary-color;
				}
			}

			a
			{
				font-size: rem-calc(20px);
			}
		}
	}
}

@-webkit-keyframes TopBarSlideInDown {
	from {
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
		visibility: visible;
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes TopBarSlideInDown {
	from {
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
		visibility: visible;
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@-webkit-keyframes TopBarSlideOutUp {
	from {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	to {
		visibility: hidden;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}
}

@keyframes TopBarSlideOutUp {
	from {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	to {
		visibility: hidden;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}
}