/* --------------------------------
   form
-------------------------------- */

form
{
	@extend %pos-relative;

	.input-wrp input,
	.input-wrp textarea,
	button[type=submit]
	{
		@extend %display-block;
		@extend %width-100;
		font-size: rem-calc(14px);
		box-shadow: none;
		outline: none;
		@include transition(
			background-color 0.3s ease-in-out,
			border-color 0.3s ease-in-out,
			color 0.3s ease-in-out
		);
	}

	.input-wrp
	{
		@extend %pos-relative;
		@extend %display-block;
		margin-bottom: 10px;

		input,
		textarea
		{
			background: none;
			background: transparent;
			border: none;
			border-bottom-width: 1px;
			border-bottom-style: solid;
			border-radius: 0;
			padding-top: 20px;
			padding-bottom: 20px;
			padding-right: 25px;
			line-height: 1.4;

			@include placeholder
			{
				@include transition(color 0.3s ease-in-out);
			}
		}

		textarea
		{
			resize: vertical;
			min-height: 160px;
		}

		i
		{
			@extend %pos-absolute;
			right: 0;
			top: 0;
			line-height: rem-calc(59px);
			font-size: rem-calc(18px);
			color: $white;
		}
	}

	button[type=submit]
	{
		line-height: 1.2;

		main & { margin-top: 10px; }
	}
}