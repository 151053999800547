/* --------------------------------
   video
-------------------------------- */

.section--video
{
	.b-video { height: 485px; }
}

.b-video
{
	@extend %pos-relative;
	padding: 125px 15px 30px;
	line-height: 1;
	@extend %text-center;
	color: $white;
	@extend %height-100;
	@extend %bg-cover;

	.b-video__inner
	{
		@extend %pos-relative;
		@extend %height-100;
		z-index: 2;
	}

	.b-video__btn-play
	{
		@extend %pos-relative;
		@extend %display-inline-block;
		@extend %v-top;
		width: 115px;
		height: 115px;
		line-height: 105px;
		border: 5px solid #fff;

		&:before
		{
			content: "";
			@extend %pos-absolute;
			bottom: 100%;
			left: 50%;
			width: 5px;
			height: 75px;
			margin-left: -2px;
			margin-bottom: -15px;
			background-color: $primary-color;
		}

		i
		{
			@extend %pos-relative;
			@extend %display-inline-block;
			@extend %v-middle;
			width: 30px;
			height: 46px;
			margin-left: 5px;

			&:before,
			&:after
			{
				content: "";
				@extend %block-absolute--full;
				@extend %bg-cover;
				@include transition(opacity 0.3s ease-in-out);
			}

			&:before
			{
				opacity: 1;
				background-image: url(../img/ico/ico_play.png);
			}

			&:after
			{
				opacity: 0;
				background-image: url(../img/ico/ico_play_hover.png);
			}
		}

		&:hover
		{
			i
			{
				&:before { opacity: 0; }
				&:after { opacity: 1; }
			}
		}
	}
}