/* --------------------------------
   custom owl carousel styles
-------------------------------- */

.owl-theme
{
	.owl-dots
	{
		line-height: 1;

		.owl-dot
		{
			@extend %v-top;
			opacity: 0.3;
			@include transition(opacity 0.2s ease-in-out);

			span
			{
				background: 0 0;
				box-shadow: 0 0 0 2px $primary-color inset;
				margin: 5px 4px;
				@include border-radius(50%);
				@include transition(
					background 0.3s ease-in-out,
					box-shadow 0.3s ease-in-out,
					opacity 0.3s ease-in-out
				);
			}

			&.active,
			&:hover
			{
				opacity: 1;

				span
				{
					background: 0 0;
					box-shadow: 0 0 0 6px $primary-color inset;
				}
			}
		}
	}
}