/* --------------------------------
   list with icon
-------------------------------- */

.list-with-icon
{
	@extend %pos-relative;
	text-align: center;

	.list__inner { margin-bottom: -35px; }

	.list__item
	{
		@extend %pos-relative;
		margin-bottom: 35px;

		&__ico
		{
			@extend %display-inline-block;
			@extend %v-top;

			&:not([class*=" fontello-"])
			{
				&:before
				{
					content: "";
					@extend %display-inline-block;
					@extend %v-middle;
					@extend %bg-cover;
				}
			}
		}

		&__title { margin-top: 0; }
	}
}

.list-with-icon--style-1
{
	.list__item__ico
	{
		height: 49px;
		line-height: 49px;
		margin-bottom: 15px;

		&--1:before
		{
			width: 52px;
			height: 42px;
			background-image: url(../img/ico/ico_1.png);
		}

		&--2:before
		{
			width: 30px;
			height: 48px;
			background-image: url(../img/ico/ico_2.png);
		}

		&--3:before
		{
			width: 40px;
			height: 48px;
			background-image: url(../img/ico/ico_3.png);
		}

		&--4:before
		{
			width: 48px;
			height: 44px;
			background-image: url(../img/ico/ico_4.png);
		}

		&--5:before
		{
			width: 46px;
			height: 38px;
			background-image: url(../img/ico/ico_5.png);
		}

		&--6:before
		{
			width: 48px;
			height: 43px;
			background-image: url(../img/ico/ico_6.png);
		}

		&--7:before
		{
			width: 48px;
			height: 37px;
			background-image: url(../img/ico/ico_7.png);
		}

		&--8:before
		{
			width: 38px;
			height: 49px;
			background-image: url(../img/ico/ico_8.png);
		}

		&--9:before
		{
			width: 44px;
			height: 49px;
			background-image: url(../img/ico/ico_9.png);
		}
	}
}

.list-with-icon--style-2
{
	.list__item
	{
		color: #cecece;

		&__ico
		{
			height: 62px;
			line-height: 62px;
			margin-bottom: 30px;

			&--1:before
			{
				width: 65px;
				height: 52px;
				background-image: url(../img/ico/ico_1.png);
			}

			&--2:before
			{
				width: 39px;
				height: 62px;
				background-image: url(../img/ico/ico_2.png);
			}

			&--3:before
			{
				width: 51px;
				height: 60px;
				background-image: url(../img/ico/ico_3.png);
			}
		}

		&__title
		{
			font-size: 25px;
			color: $white;
		}
	}
}

.list-with-icon--style-3
{
	text-align: left;

	.list__item
	{
		padding-left: 70px;

		&__ico
		{
			@extend %fl-l;
			margin-left: -70px;
			width: 40px;
			height: 40px;
			border: 2px solid $primary-color;
			line-height: rem-calc(36px);
			font-size: rem-calc(17px);
			@extend %text-center;
			color: $primary-color;
		}
	}
}

.list-with-icon--style-4
{
	.list__item
	{
		&__ico
		{
			margin-bottom: 30px;

			&:before
			{
				width: 58px;
				height: 80px;
			}

			&--1:before { background-image: url(../img/ico/ico_doc_1.png); }
			&--2:before { background-image: url(../img/ico/ico_doc_2.png); }
			&--3:before { background-image: url(../img/ico/ico_doc_3.png); }
		}
	}
}

@include min-screen($sm-width)
{
	.list-with-icon { text-align: left; }

	.list-with-icon--style-1
	{
		.col-md-pull-6,
		.col-md-pull-12
		{
			.list__item { text-align: right; }
		}
	}

	.list-with-icon--style-4
	{
		.list__item
		{
			&:before
			{
				content: '\f006';
				position: absolute;
				top: 20px;
				right: 100%;
				font: {
					size: rem-calc(25px);
					family: "fontello";
					style: normal;
					weight: normal;
					variant: normal;
				};
				speak: none;
				display: inline-block;
				text-decoration: inherit;
				width: auto;
				text-align: center;
				text-transform: none;
				line-height: 1em;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				min-width: 20px;
				margin-right: 5px;
				color: #c9c9c9;
			}
		}

		.col-xs-12:first-child
		{
			.list__item:before { content: none; }
		}
	}
}