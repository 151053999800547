*
{
	padding: 0;
	margin: 0;
}

ul, ol
{
	list-style: none;
	list-style-image: none;
}

iframe
{
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}

html.js.desktop { overflow-y: hidden; }

body { @extend %pos-relative; }

/* parallax */
.parallax
{
	@extend %pos-relative;
	@extend %bg-cover;

	.desktop & { background-attachment: fixed; }
}

/* float */
.fl-l { @extend %fl-l; }
.fl-r { @extend %fl-r; }

/* text align */
.text-center { @extend %text-center; }
.text-left   { @extend %text-left; }
.text-right  { @extend %text-right; }

/* vertical align */
.v-top    { @extend %v-top; }
.v-middle { @extend %v-middle; }
.v-bottom { @extend %v-bottom; }

.v-align
{
	@extend %height-100;

	&:before
	{
		content: "";
		@extend %width-0;
		@extend %height-100;
		margin-left: -4px;
	}

	&:before,
	>*
	{
		@extend %display-inline-block;
		@extend %v-middle;
	}

	>* { @extend %width-100; }
}

.b-table
{
	@extend %display-table;
	@extend %width-100;

	.cell { @extend %display-table-cell; }
}

/* circled element */

.circled { @include border-radius(50%); }

/* column margin bottom */

.col-MB-30 { margin-bottom: 30px; }
.col-MB-25 { margin-bottom: 25px; }
.col-MB-20 { margin-bottom: 20px; }
.col-MB-15 { margin-bottom: 15px; }

@include min-screen($sm-width)
{
	.col-sm-MB-30 { margin-bottom: 30px; }
	.col-sm-MB-25 { margin-bottom: 25px; }
	.col-sm-MB-20 { margin-bottom: 20px; }
	.col-sm-MB-15 { margin-bottom: 15px; }
}

@include min-screen($md-width)
{
	.col-md-MB-30 { margin-bottom: 30px; }
	.col-md-MB-25 { margin-bottom: 25px; }
	.col-md-MB-20 { margin-bottom: 20px; }
	.col-md-MB-15 { margin-bottom: 15px; }
}

@include min-screen($lg-width)
{
	.col-lg-MB-30 { margin-bottom: 30px; }
	.col-lg-MB-25 { margin-bottom: 25px; }
	.col-lg-MB-20 { margin-bottom: 20px; }
	.col-lg-MB-15 { margin-bottom: 15px; }
}

/* section */

.section
{
	@extend %pos-relative;
	padding-top: 60px;
	padding-bottom: 60px;

	&--background { @extend %bg-cover; }

	&--background-base       { background-color: $primary-color; }
	&--background-base-light { background-color: $primary-color-light; }
	&--background-dark       { background-color: $secondary-color; }

	&--background-base,
	&--background-dark
	{
		color: $white;
	}

	&--with-border
	{
		border-top: 1px solid #f1f1f1;
		padding-top: 59px;
	}

	@include min-screen($sm-width)
	{
		padding-top: 80px;
		padding-bottom: 80px;

		&--with-border { padding-top: 79px; }
	}

	@include min-screen($md-width)
	{
		padding-top: 100px;
		padding-bottom: 100px;

		&--with-border { padding-top: 99px; }
	}
}

/* embed responsive */

.embed-responsive
{
	@extend %pos-relative;
	@extend %display-block;
	@extend %height-0;
	padding: 0;
	overflow: hidden;

	&-item,
	iframe,
	embed,
	object,
	video
	{
		@extend %block-absolute--full;
		border: 0;
	}

	&-21by9 { padding-bottom: 42.857143%; }
	&-16by9 { padding-bottom: 56.25%; }
	&-4by3  { padding-bottom: 75%;}
	&-1by1  { padding-bottom: 100%; }
}

/* pattern */

.pattern
{
	@extend %block-absolute--full;
	background-color: $primary-color;
}

/* social buttons */

.social-btns
{
	@extend %pos-relative;
	margin-left: -5px;
	margin-right: -5px;
	font-size: 0;
	line-height: 0;
	letter-spacing: -1px;

	a
	{
		@extend %display-inline-block;
		@extend %v-bottom;
		min-width: 1em;
		margin-left: 5px;
		margin-right: 5px;
		line-height: 1;
		font-size: rem-calc(22px);
		letter-spacing: 0;
		text-decoration: none;
		color: rgba($white,0.5);
		@extend %text-center;
		@include transition(
			background-color 0.3s ease-in-out,
			color 0.3s ease-in-out
		);

		&:hover { color: $white; }
	}
}

/* top button */

#btn-to-top-wrap
{
	@extend %display-none;
	position: fixed;
	right: 25px;
	bottom: 25px;
	z-index: 5;
}

#btn-to-top
{
	@extend %pos-relative;
	@extend %display-block;
	width: 44px;
	height: 44px;
	line-height: 38px;
	font-size: 20px;
	color: $white;
	@extend %text-center;
	text-decoration: none;  
	background-color: darken($primary-color, 5%);
	opacity: 0.7;
	@include transition(opacity 0.3s ease-in-out);

	&:before
	{
		content: '';
		@extend %display-inline-block;
		@extend %v-middle;
		@extend %width-0;
		@extend %height-0;
		border-bottom: 6px solid $white;
		border-left: 7px solid transparent;
		border-right: 7px solid transparent;
	}

	&:hover { opacity: 1; }
}

.ancor { @extend %display-block; }