/* --------------------------------
   pricing table
-------------------------------- */

.pricing-table
{
	.pricing__inner { margin-bottom: -30px; }

	.price-item
	{
		@extend %pos-relative;
		overflow: hidden;
		background-color: $white;
		max-width: 400px;
		padding: 55px 15px 0;
		margin-bottom: 30px;
		@extend %text-center;

		&__label
		{
			@extend %pos-absolute;
			top: -5px;
			right: -50px;
			min-width: 90px;
			padding: 25px 10px 10px;
			line-height: 1;
			font: {
				size: 14px;
				weight: 700;
			};
			text-transform: uppercase;
			color: $primary-color;
			background-color: $white;
			transform: rotate(45deg);
			transform-origin: top center;
		}

		&__price,
		&__title,
		&__list,
		&__btn
		{
			@extend %pos-relative;
			z-index: 2;
		}

		&__price
		{
			line-height: 0.8;
			font: {
				size: 60px;
				family: $base-fontFamily-2;
				weight: 700;
			};
			color: #444;

			&:before
			{
				content: attr(data-before);
				@extend %display-inline-block;
				@extend %v-top;
				line-height: 1;
				font-size: 30px;
				margin-right: -6px;
				margin-top: 1px;
			}

			sup
			{
				font-size: 33px;
				top: -20px;
			}

			span
			{
				@extend %display-block;
				line-height: 1;
				font: {
					size: 15px;
					weight: 400;
				};
				color: #939393;
				padding-top: 15px;
			}
		}

		&__title {}

		&__list
		{
			line-height: 1;
			margin: 50px -10px 40px;

			li
			{
				padding: 8px 10px;
				line-height: 1.3;
				font-weight: 400;

				&:nth-of-type(2n+1) { background-color: #fafafa; }
			}
		}

		&__btn
		{
			@extend %display-block;
			min-height: 67px;
			line-height: 1;
			color: $white;
			@extend %text-center;
			margin: 0 -15px;
			padding: 25px 10px;
			@include transition(
				background-color 0.3s ease-in-out,
				color 0.3s ease-in-out
			);
		}

		&__bg
		{
			@extend %pos-absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			border-width: 5px 5px 0 5px;
			border-style: solid;
			@include transition(border-color 0.3s ease-in-out);
		}
	}

	.price-item__active
	{
		color: $white;
		padding: 60px 0 0;

		.price-item__price,
		.price-item__title
		{
			color: inherit;
			margin-left: 5px;
			margin-right: 5px;
		}

		.price-item__price span { color: rgba($white,0.65); }

		.price-item__list,
		.price-item__btn
		{
			margin-left: 0;
			margin-right: 0;
		}

		.price-item__list
		{
			margin-bottom: 25px;

			li
			{
				&:nth-of-type(2n+1) { background-color: rgba(#fafafa,0.1); }
			}
		}

		.price-item__bg { @extend %display-none; }
	}
}

.pricing-table--style-1
{
	.price-item
	{
		&__btn
		{
			&:active,
			&:hover
			{
				background-color: $secondary-color !important;

				+ .price-item__bg { border-color: $secondary-color !important; }
			}
		}
	}

	.price-item__green
	{
		.price-item__label { color: $success-color; }

		&.price-item__active,
		 .price-item__btn
		{
			background-color: $success-color;
		}

		.price-item__bg { border-color: $success-color; }
	}

	.price-item__red
	{
		.price-item__label { color: $danger-color; }

		&.price-item__active,
		 .price-item__btn
		{
			background-color: $danger-color;
		}

		.price-item__bg { border-color: $danger-color; }
	}

	.price-item__blue
	{
		.price-item__label { color: $info-color; }

		&.price-item__active,
		 .price-item__btn
		{
			background-color: $info-color;
		}

		.price-item__bg { border-color: $info-color; }
	}

	.price-item__yellow
	{
		.price-item__label { color: $warning-color; }

		&.price-item__active,
		 .price-item__btn
		{
			background-color: $warning-color;
		}

		.price-item__bg { border-color: $warning-color; }
	}
}

.pricing-table--style-2
{
	.price-item
	{
		&__btn
		{
			background-color: $primary-color;

			&:active,
			&:hover
			{
				background-color: #444;
			}
		}

		&__bg { border-color: #ebebeb; }
	}

	.price-item__active
	{
		background-color: $primary-color;
		border-color: $primary-color;
	}
}

@include min-screen($md-width)
{
	.pricing-table
	{
		padding-top: 30px;

		.price-item__active { margin-top: -30px; }
	}
}