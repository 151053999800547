/* --------------------------------
   feedback
-------------------------------- */

.feedback
{
	@extend %pos-relative;

	& &__inner { margin-bottom: -30px; }

	& &__item
	{
		@extend %pos-relative;
		margin-bottom: 30px;
		@extend %text-center;
	}

	& &__text
	{
		@extend %pos-relative;

		&:before
		{
			content: "“";
			@extend %pos-absolute;
			line-height: 1;
			font-size: 100px;
			font-family: $base-fontFamily-1;
			font-weight: 700;
			color: $primary-color;
			text-shadow: 4px 4px 5px rgba(#010101,0.18);
		}
	}

	& &__author
	{
		img
		{
			@extend %display-inline-block;
			@extend %v-top;
			width: auto;
		}

		.name { margin-bottom: 5px;}

		.position
		{
			margin-top: 5px;
			margin-bottom: 0;
			color: #aaaaaa;
		}
	}
}

.feedback--slider
{
	.feedback__inner,
	.feedback__item	
	{
		margin-bottom: 0;
	}

	.owl-dots
	{
		@extend %pos-absolute;
		left: 0;
		@extend %width-100;
		margin-top: 20px !important;
	}
}

.feedback--style-1
{
	.feedback__text
	{
		background-color: $white;
		padding: 35px 25px 30px 35px;
		margin-left: 15px;
		margin-bottom: 30px;
		@extend %text-left;
		box-shadow: 4px 4px 0 rgba($primary-color,0.04);

		&:before
		{
			top: 15px;
			left: -20px;
		}
	}
}

.feedback--style-2
{
	.feedback__item
	{
		@extend %width-100;
		max-width: 970px;
		margin-left: auto;
		margin-right: auto;
	}

	.feedback__text
	{
		font: {
			size: rem-calc(20px);
			family: $base-fontFamily-2;
			weight: 300;
		};
		padding-top: 40px;
		margin-bottom: 30px;

		&:before
		{
			top: -5px;
			left: 0px;
			right: 0px;
			margin: auto;
		}
	}
}

.feedback-slider--style-1
{
	.owl-carousel
	{
		.owl-stage-outer { width: calc(100% + 4px); }
	}
}

@include min-screen(481px)
{
	.feedback--style-2
	{
		.feedback__text
		{
			font-size: rem-calc(25px);
			margin-bottom: 40px;
		}
	}
}

@include min-screen($sm-width)
{
	.feedback--style-2
	{
		.feedback__text
		{
			font-size: rem-calc(30px);
			padding-top: 0;
			margin-left: 55px;
			margin-bottom: 60px;

			&:before
			{
				left: -50px;
				right: auto;
			}
		}
	}
}