/* --------------------------------
   buttons
-------------------------------- */

.custom-btn
{
	@extend %pos-relative;
	@extend %display-inline-block;
	@extend %v-middle;
	border: {
		width: 2px;
		style: solid;
		color: $white;
	};
	line-height: 1;
	font: {
		weight: 400;
		size: rem-calc(14px);
		family: $base-fontFamily-1;
	};
	@extend %text-center;
	text-decoration: none;
	box-shadow: none;
	outline: none;
	min-height: 42px;
	min-width: 150px;
	padding-top: 12px;
	padding-bottom: 12px;
	padding-left: 10px;
	padding-right: 10px;
	@include border-radius(5px);
	@include transition(
		background-color 0.3s ease-in-out,
		border-color 0.3s ease-in-out,
		color 0.3s ease-in-out
	);

	&:hover,
	&:focus,
	&.inverted,
	&.info,
	&.inverted.info:hover,
	&.inverted.info:focus,
	&.success,
	&.inverted.success:hover,
	&.inverted.success:focus,
	&.danger,
	&.inverted.danger:hover,
	&.inverted.danger:focus,
	&.warning,
	&.inverted.warning:hover,
	&.inverted.warning:focus,
	{
		color: $white;
	}

	&,
	&.inverted:hover,
	&.inverted:focus,
	&.info:hover,
	&.info:focus,
	&.inverted.info,
	&.success:hover,
	&.success:focus,
	&.inverted.success,
	&.danger:hover,
	&.danger:focus,
	&.inverted.danger,
	&.warning:hover,
	&.warning:focus,
	&.inverted.warning
	{
		color: $secondary-color;
	}

	&,
	&.inverted:hover,
	&.inverted:focus
	{
		background-color: $white;
	}

	&.light
	{
		color: $white !important;

		&,
		&.inverted:hover,
		&.inverted:focus
		{
			background-color: rgba($white,0.3);
			border-color: rgba($white,0);
		}

		&:hover,
		&:focus,
		&.inverted
		{
			background-color: transparent;
			border-color: rgba($white,0.3);
		}
	}


	&.info
	{
		background-color: $primary-color;
		border-color: $primary-color;
	}

	&.danger
	{
		background-color: #d40c0c;
		border-color: #d40c0c;
	}

	&.success
	{
		background-color: #17c697;
		border-color: #17c697;
	}

	&.warning
	{
		background-color: $warning-color;
		border-color: $warning-color;
	}

	&.inverted
	{
		&.info
		{
			&:hover,
			&:focus
			{
				background-color: $primary-color;
			}
		}

		&.danger
		{
			&:hover,
			&:focus
			{
				background-color: #d40c0c;
			}
		}

		&.success
		{
			&:hover,
			&:focus
			{
				background-color: #17c697;
			}
		}

		&.warning
		{
			&:hover,
			&:focus
			{
				background-color: $warning-color;
			}
		}
	}

	&:not(.inverted):hover,
	&:not(.inverted):focus,
	&.inverted
	{
		background-color: transparent;
	}
}